.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.mono-font {
  font-family: monospace;
}

// For dev only
pre {
  border: 1px solid var(--black);
  background-color: var(--light);
}

.link {
  cursor: pointer;
}

.align-end {
  text-align: end !important;
}
.align-left {
  text-align: left !important;
}
.align-center {
  text-align: center !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.label-placeholder {
  color: var(--Medium-Light-theme-Base, #92949C);
  font-feature-settings: 'clig' off, 'liga' off;

  /* list / title */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.label-selection, .label-header {
  color: var(--Black-Base, #000);
  font-feature-settings: 'clig' off, 'liga' off;

  /* list / title */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.label-sub-header {
  overflow: hidden;
  color: var(--Medium-Light-theme-Base, #92949C);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* chip */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.label-title, .label-text {
  color: var(--Black-Base, #000) !important;
  font-feature-settings: 'clig' off, 'liga' off;

  /* chip */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.card-title {
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  //padding-left: 8px;
  padding-right: 16px;
  margin-left: 16px;
  margin-right: 16px;
  text-transform: uppercase;
}
