// Defaults
$extra-small: 4px;
$small: 8px;
$medium: 16px;
$large: 32px;
$extra-large: 64px;

// Border radius
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 16px;
$border-radius-xl: 24px;

// Spacing
$spacing-extra-small: $extra-small;
$spacing-small: $small;
$spacing-medium: $medium;
$spacing-large: $large;
$spacing-extra-large: $extra-large;


:root {
  // Border radius
  --border-radius-s: #{$border-radius-s};
  --border-radius-m: #{$border-radius-m};
  --border-radius-l: #{$border-radius-l};
  --border-radius-xl: #{$border-radius-xl};
  // Spacing
  --spacing-xs: #{$spacing-extra-small};
  --spacing-s: #{$spacing-small};
  --spacing-m: #{$spacing-medium};
  --spacing-l: #{$spacing-large};
  --spacing-xl: #{$spacing-extra-large};
}
