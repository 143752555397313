/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Quil */
@import '~node_modules/quill/dist/quill.core.css';
@import '~node_modules/quill/dist/quill.snow.css';

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

/* Custom */
@import "./styles/reset.scss";
@import "./styles/colors.scss";
@import "./styles/ionic-defaults.scss";
@import "./styles/tags.scss";
@import "./styles/variables.scss";
@import "./styles/fullcalendar.scss";
@import "./styles/typography.scss";
@import "./styles/context-menu.scss";
@import "./styles/registration-fab.scss";

.context-menu-modal {
  --height: auto;
}

.image-editor-modal {
  --width: 100%;
  --height: 100%;
  padding-top: var(--ion-safe-area-top, 0);
}

/* Theme variables */
:root {
  .staging-theme {
    --ion-color-primary: #2E69FF;

    ion-toolbar {
      --background: #2E69FF;
    }
  }
}

.page-loader-container, .modal-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.page-loader {
  width: 100px;
  height: 100px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.ion-card-header-has-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

// Google autocomplete
// Fix for modals that have a huge z-index
// https://developers.google.com/maps/documentation/javascript/place-autocomplete#style_autocomplete
.pac-container {
  z-index: 99999999;

  .pac-item {
    cursor: pointer;
    padding: var(--spacing-s);
    font-size: 10px;
  }
}

.no-documents {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-l);
  flex-direction: column;
  padding: var(--spacing-s);
  width: 100%;
  height: 100%;

  h3 {
    font-weight: 600;
    text-align: center;
    padding: 0 var(--spacing-l);
  }
}

.ion-datetime-modal {
  min-height: 350px; // Height of datetimepicker
  --height: auto;
  --background: none;
  --backdrop-opacity: 0.4 !important;
}

.button-icon-start {
  margin-right: var(--spacing-s);
}

.button-icon-end {
  margin-left: var(--spacing-s);
}
