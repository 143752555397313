.start-time-registration-button {
  //56px width of + icon button
  //24px margin between 2 buttons
  width: calc(100% - 56px - 24px - 24px);
  left: var(--spacing-m);
  height: 56px;

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--white);
    padding: var(--spacing-s);
    background-color: #1A2D60;
    border-radius: var(--border-radius-m);

    .timer {
      font-size: 20px;
    }

    &__left {
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      i.fa-stopwatch {
        font-size: 22px;
      }
    }

    button {
      font-size: 16px;
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 0 var(--spacing-s);

      ion-icon {
        font-size: 20px;
      }
    }

    button.stop {
      color: #EC445A;

      &:active {
        background-color: lighten(#EC445A, 80%);
      }
    }

    button.start {
      color: var(--primary-color);

      &:active {
        background-color: lighten(#2e69ff, 80%);
      }
    }
  }
}

.add-registration-button {
  right: var(--spacing-m);
}

.start-time-registration-button, .add-registration-button {
  bottom: calc(66px + env(safe-area-inset-bottom));
}
