full-calendar {
  padding: 0 var(--spacing-m);
}

.fc-theme-standard .fc-list {
  border: none;
}

.fc-theme-standard .fc-list-day-cushion {
  background: #EFF3F8;
}

.fc-direction-ltr .fc-list-day-text, .fc-direction-rtl .fc-list-day-side-text {
  float: none;
  color: var(--light-dark-theme-base, #222428);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.fc-direction-ltr .fc-list-day-side-text, .fc-direction-rtl .fc-list-day-text {
  float: none;
  margin-left: var(--spacing-m);
  color: rgba(0, 0, 0, 0.50);
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.7px;
}

.fc th {
  text-align: left;
}

.fc-theme-standard td, .fc-theme-standard th {
  border: none;
}

.fc-list-day:not(:first-child) {
  border-top: 1px solid var(--border-color, #E0E0E0);
}

.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding: 8px var(--spacing-m) 8px 0;
}

.fc .fc-list-day-cushion, .fc .fc-list-table td {
  padding: 8px 0;
}

.fc .fc-list-empty {
  background-color: transparent;
}

.fc-day-today a {
  color: var(--primary-color) !important;
}