.context-menu-container {
  padding: var(--spacing-m) var(--spacing-m) 24px var(--spacing-m);

  .context-menu-items {
    margin-bottom: var(--spacing-m);

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      font-weight: 600;
      padding: 12px 8px;
      border-bottom: 1px solid var(--ion-color-medium);

      &__left {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      ion-icon {
        font-size: 24px;
        color: var(--primary-color);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .context-menu-title {
    color: var(--Light-Dark-theme-Base, #222428);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .context-menu-buttons {
    display: flex;
    margin-top: 20px;
    gap: var(--spacing-s);

    ion-button {
      width: 50%;
    }
  }
}
