// get your color names here: http://chir.ag/projects/name-that-color
// why: https://davidwalsh.name/sass-color-variables-dont-suck


$white: #fff;
$iron: #D7D8DA;
$catskill-white: #EFF3F8;
$jumbo: #808289;

:root {
  --white: #{$white};
  --iron: #{$iron};
  --catskill-white: #{$catskill-white};
  --jumbo: #{$jumbo};

  --primary-color: #2E69FF;

  // New ionic color
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-grey: #64748B;
  --ion-color-grey-rgb: 100, 116, 139;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255, 255, 255;
  --ion-color-grey-shade: #58667a;
  --ion-color-grey-tint: #748297;

  --ion-color-lightblue: #DAE3EE;
  --ion-color-lightblue-rgb: 218, 227, 238;
  --ion-color-lightblue-contrast: #000000;
  --ion-color-lightblue-contrast-rgb: 0, 0, 0;
  --ion-color-lightblue-shade: #c0c8d1;
  --ion-color-lightblue-tint: #dee6f0;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

.primary {
  color: var(--primary-color);
}

.danger {
  color: var(--ion-color-danger);
}

.success {
  color: var(--ion-color-success);
}